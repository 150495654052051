//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      keywords_type: {
        order_no: '',
        receiver_phone: '',
        receiver_name: '',
        goods_name: '',
        refund_sn: '',
        store_id: '',
      },
      searchForm: {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        payment_code: '',
        refund_state: 0,
      },
      list: [],
      total_number: 0,
      order_num: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    tabClick() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList(1);
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        payment_code: '',
        refund_state: '',
      };
      this.resetKeywordType();
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    resetKeywordType(key) {
      for (let i in this.keywords_type) {
        if (i != key) this.keywords_type[i] = '';
      }
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.resetKeywordType('order_no');
          searchForm.keywords_type = 'order_no';
          break;
        case 2:
          this.resetKeywordType('refund_sn');
          searchForm.keywords_type = 'refund_sn';
          break;
        case 3:
          this.resetKeywordType('goods_name');
          searchForm.keywords_type = 'goods_name';
          break;
        case 4:
          this.resetKeywordType('receiver_name');
          searchForm.keywords_type = 'receiver_name';
          break;
        case 5:
          this.resetKeywordType('receiver_phone');
          searchForm.keywords_type = 'receiver_phone';
          break;
      }
      searchForm.keywords = val;
    },
    getList(is_tab) {
      let searchForm = Object.assign(this.searchForm);
      let data = {
        page: searchForm.page,
        rows: searchForm.rows,
      };
      if (searchForm.refund_state) {
        data.refund_state = searchForm.refund_state;
      }
      if (searchForm.keywords) {
        data.keywords = searchForm.keywords;
        data.keywords_type = searchForm.keywords_type;
      }
      if (searchForm.payment_code) {
        data.payment_code = searchForm.payment_code;
      }
      if (searchForm.create_time.length) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
        data.create_time = searchForm.create_time;
      }
      this.$axios.post(this.$api.samecity.service.orderRefundList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
      if (!is_tab) {
        this.$axios.post(this.$api.samecity.service.orderRefundNum, data).then(res => {
          if (res.code == 0) {
            this.order_num = res.result;
          }
        });
      }
    },
  },
};
